import React from 'react';
import { Table } from '@mantine/core';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';
import Tabs from '../../../components/Tabs';
import ogunnowo from '../../../assets/images/departments/ogunnowo.jpg';
import osibogun from '../../../assets/images/departments/osibogun.png';
import onajole from '../../../assets/images/departments/onajole.png';
import odeyemi from '../../../assets/images/departments/odeyemi.jpg';
import team1 from '../../../assets/images/team-1-1.jpg';
import olufunlayo from '../../../assets/images/departments/olufunlayo.png';
import oridota from '../../../assets/images/departments/oridota.png';
import balogun from '../../../assets/images/departments/balogun.png';
import roberts from '../../../assets/images/departments/roberts.png';
import abiola from '../../../assets/images/departments/abiola.png';
import odukoya from '../../../assets/images/departments/odukoya.jpg';

const CommunityHealth = () => {
  const subs = [
    {
      name: 'Epidemiology and Disease Control',
      services:
        'Disease surveillance activities, tuberculosis management in dots centre, vaccination activities, endemic disease control, tobacco control activities',
    },
    {
      name: 'Reproductive Health',
      services:
        'Well woman clinic, family planning, cancer screening, child welfare services',
    },
    {
      name: 'Public Health Nutrition',
      services: 'Nutrition clinic',
    },
    {
      name: 'Environmental Health',
      services: 'Technical expertise as required by management',
    },
    {
      name: 'Occupational Health',
      services: 'Technical expertise as required by management',
    },
    {
      name: 'Health Management',
      services: 'Quality assurance assessment as required by management',
    },
    {
      name: 'Primary Health Care',
      services: 'Rural health care services in model phc ',
    },
    {
      name: 'Rehabilitative Medicine',
      services: 'Geriatric clinic',
    },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.services}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Department of Community Health & Primary Care | LUTH'>
      <NavOne />
      <PageHeader title='Department of Community Health & Primary Care' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Community Health & Primary Care
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <p className='course-details__tab-text'>
                      The Department of Community Health of the Lagos University
                      Teaching Hospital (LUTH) has a mission of producing
                      leaders in public health for the country, provision of
                      clinical services and community-oriented research through
                      the use of innovative and technologically driven methods
                      by highly competent and motivated staff. <br />
                      <br />
                      The mandate of the department is focused on all aspects of
                      Public health, which is the art and science of preventing
                      disease, promoting health and prolonging life through
                      organised efforts of the society.
                      <br />
                      <br />
                      The current head of Department is Prof B.E Ogunnowo
                    </p>
                  </div>
                  <div label='Services'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Community Health Outpatient Clinic (CHOP)
                    </h2>
                    <p className='course-details__tab-text'>
                      Immunoprophylaxis & Child Welfare Clinic: is also located
                      at the Community Health Out-Patient (CHOP). It is open to
                      all children under the age of five years. The clinic runs
                      daily from Tuesday to Friday. Services provided include:{' '}
                      <br />
                      • Growth monitoring
                      <br />
                      • Immunization (VPDs, HPV, Mumps etc.) <br />• Treatment
                      of common childhood illnesses (URTIs, Mild to moderate
                      malaria, mild to moderate diarrhoea, minor skin
                      infections, allergy, PEM) <br />• Health education on
                      common childhood illnesses. <br />
                      • Nutritional Counselling and Food Demonstration. <br />
                      • Referral of patient to other departments <br />
                      The department also provides vaccination services for
                      adults and was actively involved in the provision of
                      COVID-19 vaccination for staff and the general public
                    </p>
                    <p className='course-details__tab-text'>
                      <br />
                      Reproductive Health Clinic/Wellwoman clinic: Is located in
                      the Community Health Outpatient Department, LUTH. The
                      clinic is opened every day from Monday to Thursday.
                      Patients can walk in, as no referral is required. The
                      clinic provides the following services:
                      <br />• Family planning service
                      <br />
                      • Health education on reproductive health conditions
                      <br />
                      • Annual wellness check-ups
                      <br />
                      • Investigation and treatment of common genital infections
                      (e.g., STIs, Cystitis etc.)
                      <br />
                      • Screening for cancer of the reproductive health system
                      (Cervical, ovarian, breast)
                      <br />
                      • Sub-fertility investigation. <br />• Referral of
                      client/patient to other departments
                      <br />
                      <br />
                      Nutrition clinic: is run every Friday at the CHOP. It is
                      targeted primarily towards under-five year old children
                      with nutritional problems. Undernourished and malnourished
                      children are referred from the immunoprophylaxis clinic
                      and other departments of the hospital. The children are
                      investigated for underlying problems, and individualized
                      nutrition education and follow-up monitoring are provided.
                      Food demonstrations are organised fortnightly to educate
                      mothers about the use of locally available food stuff in
                      manners that enhance the nutritional status of the child.
                      The clinic is run by resident doctors and nurses under the
                      supervision of consultants in charge.
                      <br />
                      <br />
                      Endemic Disease Clinic/Geriatric Clinic: is also run every
                      Friday at the CHOP. It is open to all patients with
                      prevalent endemic diseases such as Type 2 diabetes,
                      hypertension, osteoarthritis, etc. Services provided
                      include the management of uncomplicated endemic diseases,
                      tobacco cessation services, geriatric services, education
                      and nutritional counselling. The clinic is run by the
                      residents under the supervision of consultants. Monthly
                      meetings are held with the staff of CHOP, the residents
                      and the consultants to review the month's activities.
                    </p>
                    <h2 className='blog-one__title' style={{ marginTop: 22 }}>
                      DOTS Center
                    </h2>
                    <p className='course-details__tab-text'>
                      This is for the management of Tuberculosis, a major public
                      health problem in Nigeria. The clinic runs daily.
                    </p>
                    <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                      Sub-Specialities and Services Rendered
                    </h2>
                    <Table fontSize='md' striped>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Services Rendered</th>
                        </tr>
                      </thead>
                      <tbody>{subRows}</tbody>
                    </Table>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunnowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof B.E Ogunnowo</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS MPH, FMCPH
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Occupational, Health/Epidemiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osibogun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof A.O. Osibogun</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH, FWACP, MD.
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Health, Management/Epidemiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={onajole} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof A.T Onajole</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH,FWACP
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Epidemiology and disease control
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={odeyemi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof K.A Odeyemi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH, FWACP, MD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Reproductive Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr O.O Ayankogbe</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, MPH (Lagos), CCRA (Canada)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olufunlayo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr T. F Olufunlayo</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH FMCPH.
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Health Management/Public Health Nutrition
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oridota} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr E.S Oridota</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Epidemiology and disease control
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={balogun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr M.R Balogun</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH, FWACP
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Reproductive Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={roberts} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr A.A Roberts</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH, FWACP
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Primary Health, Care/Rehabilitative medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={abiola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr O.A Abiola</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FWACP
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Environmental, Health/Epidemiology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={odukoya} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr O.O Odukoya</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Epidemiology and disease control
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default CommunityHealth;
